<template>
  <div>
    <ClientAppointments v-bind="bind" @referenceIdSelected="eventSelected" />
    <!-- event info -->
    <b-modal
      size="lg"
      :title="infoModalCalendarTitle"
      class="modal-info"
      v-model="infoModalCalendar"
      hide-footer
      @ok="infoModalCalendar = false"
    >
      <event-info :eventData="eventSelectedData"></event-info>
      <!-- template #modal-footer="{ ok }">
        <b-button
          variant="warning"
          :to="{ name: 'ClientAppointmentEdit', params: { id: infoModalEntryId } }"
          >Edit</b-button
        >
        <b-button @click="ok()">Close</b-button>
      </template -->
    </b-modal>
  </div>
</template>

<script>
import ClientAppointments from './-ClientAppointments'
import EventInfo from '../calendar/~EventInfo'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { ClientAppointments, EventInfo },
  data: () => ({
    eventSelectedData: {},
    // event info modal
    infoModalCalendar: false,
    infoModalCalendarTitle: '',
    infoModalCalendarContent: '',
    infoModalCalendarCancelDisabled: true,
    infoModalEntryId: null,
  }),
  computed: {
    ...mapGetters(['clientAppointments']),
    bind() {
      return {
        iniFranchiseCode: this.currentFranchise.franchiseCode || null,
        clientId: this.$route.query.cid || null,
        type: this.$route.params.type || null,
        iniDateStart: this.$d().subtract(3, 'months').format('YYYY-MM-DD'),
      }
    },
  },
  methods: {
    ...mapActions(['getClientAppointment']),
    async eventSelected(eid) {
      const event = await this.getClientAppointment(eid)
      if (!event.id) {
        console.info('no event found')
        return
      }
      this.eventSelectedData = event
      this.infoModalCalendarTitle =
        `${event.client.fname} ${event.client.lname}` + ' (#' + event.client.id + ')'
      this.infoModalEntryId = event.id
      this.infoModalCalendar = true
    },
  },
}
</script>
